import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PostsExcert } from '../components/post-excerpt'
import { PageProps } from '../types'

const BlogIndex: React.FC<PageProps<unknown>> = ({ data, location }) => {
  const pages = data.pages.nodes.map(node => node.childMarkdownRemark)
  return (
    <Layout
      location={location}
      site={data.site}
      pages={data.pages}
      title="Pages"
      nav={[{ href: '/pages', title: 'Pages' }]}>
      <SEO title="Pages" />
      {(pages.length && (
        <PostsExcert posts={pages} site={data.site} isPost={false} />
      )) || (
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      )}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          id
          excerpt(pruneLength: 350)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            categories
            title
            description
            tags
          }
        }
      }
    }
  }
`
